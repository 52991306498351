@use '../styles/theme';

:local(.icon-button) {
  display: flex;
  align-items: center;
  background: transparent;
  border-width: 0;
  border-color: transparent;
  font-size: theme.$font-size-xs;
  font-weight: theme.$font-weight-bold;
  cursor: pointer;

  svg {
    color: theme.$text1-color;
  }

  & > * {
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }

  &:hover {
    color: theme.$primary-color-hover;

    svg {
      color: theme.$primary-color-hover;
    }
  }

  &:active {
    color: theme.$primary-color-pressed;

    svg {
      color: theme.$primary-color-pressed;
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: theme.$disabled-icon-color;
    cursor: not-allowed;

    svg {
      color: theme.$disabled-icon-color;
    }
  }

  // For file upload buttons
  input {
    opacity: 0;
    width: 0;
    overflow: hidden;

    &:focus {
      outline: none;
    }

    &::-webkit-file-upload-button, &::file-selector-button {
      visibility: hidden;
    }
  }

  &:local(.compact-sm) {
    @media(max-width: theme.$breakpoint-lg - 1), (max-height: theme.$breakpoint-vr - 1) {
      flex-direction: column;
      justify-content: center;

      & > * {
        margin-right: 0;
        margin-bottom: 4px;
    
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  :global(.keyboard-user) &:focus-within {
    box-shadow: 0 0 0 2px theme.$outline-color;
  }
}

:local(.lg) {
  @media(min-width: theme.$breakpoint-lg) and (min-height: theme.$breakpoint-vr) {
    font-size: theme.$font-size-sm;

    svg {
      height: 24px;
      width: 24px;
    }
  }
}
